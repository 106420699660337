import auth from "@/middleware/auth";
import checkLogin from "@/middleware/check-login";

export default [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/Home.vue"),
    meta: {
      // pageTitle: "Home",
      middleware: [auth],
      // breadcrumb: [
      //   {
      //     text: "Home",
      //     active: true,
      //   },
      // ],
    },
  },
  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import("@/views/Login.vue"),
  //   meta: {
  //     middleware: [checkLogin],
  //     layout: "full",
  //   },
  // },
  {
    path: "/welcome",
    name: "welcome",
    component: () => import("@/views/Welcome.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/auto-login",
    name: "auto-login",
    component: () => import("@/views/AutoLogin/index.vue"),
    meta: {
      layout: "full",
    },
  },
  {
    path: "/error-404",
    name: "error-404",
    component: () => import("@/views/Error404.vue"),
    meta: {
      layout: "full",
    },
  },
];
