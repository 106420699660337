import BaseRequest from "../BaseRequest"

export default class EcomRequest extends BaseRequest {
  getListProducts(params) {
    return this.get(`go/ecom/products`, params)
  }
  createOrder(params) {
    return this.postLocal(`go/ecom/orders/create`, params)
  }
  cancelOrder(id) {
    return this.postLocal(`go/ecom/orders/cancel/${id}`)
  }
  getDetailProduct(slug) {
    return this.get(`go/ecom/products/${slug}`)
  }
  getListBrands(params) {
    return this.get(`go/ecom/brand`, params)
  }
  getListManufacturers(params) {
    return this.get(`go/ecom/manufacturer`, params)
  }
  getListOrigins(params) {
    return this.get(`go/ecom/origin`, params)
  }

  getDetailOrder(id) {
    return this.getLocal(`go/ecom/orders/${id}`)
  }
  getListOrders(params) {
    return this.get(`go/ecom/orders/my`, params)
  }
  getDoctorPoints(params) {
    return this.getLocal("go/ecom/hodo_point/balance", params)
  }
  getProductCategoryList(params) {
    return this.get("go/ecom/category", params)
  }
  getAddressListOfUser(params) {
    return this.get("go/ecom/customer", params)
  }
  getAddressById(id) {
    return this.get("go/ecom/customer/" + id)
  }
  createAddress(params) {
    return this.post("go/ecom/customer", params)
  }
  updateAddress(id, params) {
    return this.put("go/ecom/customer/" + id, params)
  }
  deleteAddress(id) {
    return this.delete("go/ecom/customer/" + id)
  }
  getPointHistory(params) {
    return this.getLocal("go/ecom/buyer_transactions", params)
  }
  postRequestPayment(params) {
    return this.postLocal(`go/core/payment/requestpayment`, params)
  }
}
