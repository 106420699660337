import auth from "@/middleware/auth";

export default [
  {
    path: "/e-commerce/shop",
    name: "apps-e-commerce-shop",
    component: () =>
      import("@/views/pages/e-commerce/e-commerce-shop/ECommerceShop.vue"),
    meta: {
      middleware: [auth],
      contentRenderer: "sidebar-left-detached",
      contentClass: "ecommerce-application",
      pageTitle: "Shop",
      breadcrumb: [
        {
          text: "ECommerce",
        },
        {
          text: "Shop",
          active: true,
        },
      ],
    },
  },
  {
    path: "/e-commerce/wishlist",
    name: "apps-e-commerce-wishlist",
    component: () =>
      import(
        "@/views/pages/e-commerce/e-commerce-wishlist/ECommerceWishlist.vue"
      ),
    meta: {
      middleware: [auth],
      pageTitle: "Wishlist",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "ECommerce",
        },
        {
          text: "Wishlist",
          active: true,
        },
      ],
    },
  },
  {
    path: "/e-commerce/checkout",
    name: "apps-e-commerce-checkout",
    component: () =>
      import(
        "@/views/pages/e-commerce/e-commerce-checkout/ECommerceCheckout.vue"
      ),
    meta: {
      middleware: [auth],
      pageTitle: "Checkout",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "ECommerce",
        },
        {
          text: "Checkout",
          active: true,
        },
      ],
    },
  },
  {
    path: "/e-commerce/:slug",
    name: "apps-e-commerce-product-details",
    component: () =>
      import(
        "@/views/pages/e-commerce/e-commerce-product-details/ECommerceProductDetails.vue"
      ),
    meta: {
      middleware: [auth],
      pageTitle: "Product Details",
      contentClass: "ecommerce-application",
      breadcrumb: [
        {
          text: "ECommerce",
        },
        {
          text: "Shop",
          active: true,
        },
        {
          text: "Product Details",
          active: true,
        },
      ],
    },
  },
]
