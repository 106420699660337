import appUtils from "../utils/appUtils";

export default class BaseRequest {
  getUrlPrefix() {
    return process.env.VUE_APP_BASE_API + "api/";
  }
  getUrlPrefixLocal() {
    const endPoint = process.env.VUE_APP_BASE_URL_GO.replace("api/go/", "");
    return endPoint + "api/";
  }

  post(url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      let options = {};
      if (headers) {
        options.headers = headers;
      }

      window.axios
        .post(this.getUrlPrefix() + url, data, options, showMessage)
        .then((response) => {
          if (response.data.message && showMessage) {
            console.log(response.data.message);
          }
          if (response.data.code === 200) {
            resolve(response.data);
            return;
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response);
            return;
          }
        })
        .catch((error) => {
          this._errorHandler(reject, error, showMessage);
        });
    });
  }
  postLocal(url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      let options = {};
      if (headers) {
        options.headers = headers;
      }

      window.axios
        .post(this.getUrlPrefixLocal() + url, data, options, showMessage)
        .then((response) => {
          if (response.data.message && showMessage) {
            console.log(response.data.message);
          }
          if (response.data.code === 200) {
            resolve(response.data);
            return;
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response);
            return;
          }
        })
        .catch((error) => {
          this._errorHandler(reject, error, showMessage);
        });
    });
  }

  delete(url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      let options = {};
      if (headers) {
        options.headers = headers;
      }
      window.axios
        .delete(this.getUrlPrefix() + url, data, options, showMessage)
        .then((response) => {
          if (response.data.message && showMessage) {
            console.log(response.data.message);
          }
          if (response.data.code === 200) {
            resolve(response.data);
            return;
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response);
            return;
          }
        })
        .catch((error) => {
          this._errorHandler(reject, error, showMessage);
        });
    });
  }
  deleteLocal(url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      let options = {};
      if (headers) {
        options.headers = headers;
      }
      window.axios
        .delete(this.getUrlPrefixLocal() + url, data, options, showMessage)
        .then((response) => {
          if (response.data.message && showMessage) {
            console.log(response.data.message);
          }
          if (response.data.code === 200) {
            resolve(response.data);
            return;
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response);
            return;
          }
        })
        .catch((error) => {
          this._errorHandler(reject, error, showMessage);
        });
    });
  }

  put(url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      let options = {};
      if (headers) {
        options.headers = headers;
      }
      window.axios
        .put(this.getUrlPrefix() + url, data, options, showMessage)
        .then((response) => {
          if (response.data.message && showMessage) {
            console.log(response.data.message);
          }
          if (response.data.code === 200) {
            resolve(response.data);
            return;
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response);
            return;
          }
        })
        .catch((error) => {
          this._errorHandler(reject, error, showMessage);
        });
    });
  }
  putLocal(url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      let options = {};
      if (headers) {
        options.headers = headers;
      }
      window.axios
        .put(this.getUrlPrefixLocal() + url, data, options, showMessage)
        .then((response) => {
          if (response.data.message && showMessage) {
            console.log(response.data.message);
          }
          if (response.data.code === 200) {
            resolve(response.data);
            return;
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response);
            return;
          }
        })
        .catch((error) => {
          this._errorHandler(reject, error, showMessage);
        });
    });
  }

  get(url, params, showMessage, configs = null) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(this.getUrlPrefix() + url, { params, ...configs })
        .then((response) => {
          if (response.data.code === 200) {
            resolve(response.data);
            return;
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response);
          }
        })
        .catch((error) => {
          this._errorHandler(reject, error, showMessage);
        });
    });
  }
  getLocal(url, params, showMessage, configs = null) {
    return new Promise((resolve, reject) => {
      window.axios
        .get(this.getUrlPrefixLocal() + url, { params, ...configs })
        .then((response) => {
          if (response.data.code === 200) {
            resolve(response.data);
            return;
          }
          if (response.status === 200 || response.status === 201) {
            resolve(response);
          }
        })
        .catch((error) => {
          this._errorHandler(reject, error, showMessage);
        });
    });
  }

  _errorHandler(reject, err, showMessage = true) {
    try {
      if (err.response) {
        if (err.response.status === 401 || err.response.status === 406) {
          appUtils.removeAll();
        } else if (err.response.data.status === 414 && showMessage) {
          let firstKey = Object.keys(err.response.data.message)[0];
          if (firstKey) {
            console.log(err.response.data.message[firstKey][0]);
            return reject({
              message: err.response.data.message[firstKey][0],
            });
          }
        } else if (err.response.status === 422 && showMessage) {
          let messages = Object.values(err.response.data.errors).map(
            (errs) => errs[0]
          );
          console.log(messages[0]);
          return reject({
            message: messages[0],
          });
        } else if (
          (err.response.status === 500 || err.response.code === 500) &&
          showMessage
        ) {
          console.log(err);
          return reject(err);
        } else if (err.response.status === 409) {
          const error = err.response.data["Error!"];
          console.log(error);
          return reject(error);
        } else if (err.response.data.message && showMessage) {
          console.log(err.response.data.message);
          return reject({
            message: err.response.data.message,
          });
        } else {
          console.log(err);
        }
      }

      reject(err);
    } catch (error) {
      console.log(error);
    }
  }

  postByUrl(url, data = {}, headers = null, showMessage) {
    return new Promise((resolve, reject) => {
      const options = {};
      if (headers) {
        options.headers = headers;
      }

      window.axios
        .post(url, data, options, showMessage)
        .then((response) => {
          if (response.data.message && showMessage) {
            console.log(response.data.message);
          }
          if (response.data.code === 200) {
            resolve(response.data);
            return;
          }
          if (response.status === 200) {
            resolve(response);
            return;
          }
          if (response.status === 406) {
            // window.location.href = "/login";
          }
        })
        .catch((error) => {
          this._errorHandler(reject, error, showMessage);
        });
    });
  }
}
