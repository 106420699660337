import AuthRequest from "./request/AuthRequest"
import CommonRequest from "./request/CommonRequest"
import appUtils from "../utils/appUtils"
import EcomRequest from "./request/EcomRequest"

const requestMap = {
  AuthRequest,
  CommonRequest,
  EcomRequest
}
const instances = {}

export default class RequestFactory {
  getRequest(classname) {
    if (appUtils.getLocalToken()) {
      window.axios.defaults.headers.common["Authorization"] =
        "Bearer " + appUtils.getLocalToken()
    }

    window.axios.defaults.headers.common["Content-Type"] = "application/json"

    let RequestClass = requestMap[classname]
    if (!RequestClass) {
      throw new Error("Invalid request class name: " + classname)
    }

    let requestInstance = instances[classname]
    if (!requestInstance) {
      requestInstance = new RequestClass()
      instances[classname] = requestInstance
    }
    return requestInstance
  }
}
