<template>
  <div class="p-2">
    <div class="mb-1 w-full flex flex-col gap-4">
      <div class="flex items-center gap-4">
        <div class="search flex flex-col flex-1">
          <span class>Tìm kiếm</span>
          <b-input-group>
            <b-input-group-prepend is-text>
              <b-icon icon="search"></b-icon>
            </b-input-group-prepend>
            <b-form-input
              v-model="filter.keyword"
              type="search"
              placeholder="Tìm kiếm"
              debounce="500"
              @update="handleSearch"
            ></b-form-input>
          </b-input-group>
        </div>

        <div class="order-date flex flex-col flex-1">
          <span class>Từ ngày</span>
          <b-form-datepicker
            id="from-date"
            v-model="filter.from_date"
            class="order-date"
            @input="getListOrders"
            placeholder="Chọn ngày"
          ></b-form-datepicker>
        </div>
        <div class="order-date flex flex-col flex-1">
          <span class>Đến ngày</span>
          <b-form-datepicker
            id="to-date"
            v-model="filter.to_date"
            class="order-date"
            placeholder="Chọn ngày"
            @input="getListOrders"
          ></b-form-datepicker>
        </div>
      </div>
      <div class="flex items-center gap-4">
        <div class="order-status flex flex-col flex-1">
          <span class>Trạng thái đơn hàng</span>
          <b-form-select
            v-model="filter.status"
            :options="optionsStatus"
            @change="getListOrders"
          ></b-form-select>
        </div>
        <div class="payment-status flex flex-col flex-1">
          <span class>Trạng thái thanh toán</span>
          <b-form-select
            v-model="filter.payment_status"
            :options="optionsPaymentStatus"
            @change="getListOrders"
          ></b-form-select>
        </div>
        <div class="flex-1"></div>
      </div>
    </div>
    <!-- <b-tabs content-class="mt-3" align="center">
      <b-tab
        v-for="(item, index) in tabs"
        :key="index"
        :title="item.title"
        :active="item.value === tabActive"
    >-->
    <p class="mt-2 font-medium text-black fs-16">
      Có {{ totalOrder }} đơn hàng
    </p>
    <div
      class="mt-2 pb-4 cursor-pointer scroll-bar"
      v-if="orders.length"
      id="infinite-list"
      v-loading="isLoading"
    >
      <div
        class="order mb-1"
        v-for="item in convertOrders"
        :key="item.id"
        @click="
          goToOrderDetail(item);
          isConflictClick = false;
        "
      >
        <div class="flex justify-between items-start mb-1">
          <p class="order-code mb-0 flex-1">
            Mã đơn hàng
            <span class="font-medium">#{{ item.order_code }}</span>
          </p>
          <div
            :set="(statusInfo = getStatusByValue(item.status))"
            class="flex-1 text-right flex w-fit justify-end gap-2"
          >
            <div>
              <i class="text-base !text-gray-600">{{
                moment(item.created_at).format("HH:mm DD/MM/YYYY")
              }}</i>
            </div>
            <b-badge :variant="statusInfo.variant">{{
              statusInfo.label
            }}</b-badge>
          </div>
        </div>

        <div class="mb-2 flex order-wrapper">
          <div class="flex-1 product-image">
            <img
              :src="
                item.firstItem.product && item.firstItem.product.primary_image
              "
              alt
              class="mx-auto object-cover rounded-lg"
              @error="(e) => appUtils.onHandleErrorLoadImage(e)"
            />
          </div>
          <div class="product-information">
            <div>
              <p class="font-semibold name">
                {{ item.firstItem.product && item.firstItem.product.name }}
              </p>
              <p class="quantity">
                {{
                  item.firstItem.product &&
                  item.firstItem.product.specifications
                }}
              </p>
              <div class="price">
                {{ item.firstItem.product && item.firstItem.qty }} x
                {{
                  item.firstItem.product &&
                  appUtils.numberFormat(item.firstItem.price)
                }}đ/
                {{
                  item.firstItem.variants &&
                  item.firstItem.variants.ecom_unit &&
                  item.firstItem.variants.ecom_unit.name
                }}
              </div>
            </div>
          </div>
        </div>

        <div v-if="isShowMoreProduct" style="transition: all 0.3s ease-out">
          <div
            class="mb-2 flex order-wrapper"
            v-for="prd in item.moreItems"
            :key="prd.id"
          >
            <div class="flex-1 product-image">
              <img
                :src="prd.product.primary_image"
                alt
                class="mx-auto object-cover rounded-lg"
                @error="(e) => appUtils.onHandleErrorLoadImage(e)"
              />
            </div>
            <div class="product-information">
              <div>
                <p class="fs-16 font-bold name">{{ prd.product.name }}</p>
                <p class="quantity">{{ prd.product.specifications }}</p>
                <div class="price">
                  {{ prd.qty }} x {{ appUtils.numberFormat(prd.price) }}đ/
                  {{
                    prd.variants &&
                    prd.variants.ecom_unit &&
                    prd.variants.ecom_unit.name
                  }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <p
          class="view-more cursor-pointer mt-1"
          v-if="
            !isShowMoreProduct && item.moreItems && item.moreItems.length > 0
          "
          @click="showMoreProduct(item)"
        >
          Nhấn để xem thêm sản phẩm
        </p>
        <div class="total">
          <div :set="(paymentInfo = handleGetPaymentInfo(item.payment_status))">
            <b-badge :variant="paymentInfo.variant">{{
              paymentInfo.label
            }}</b-badge>
          </div>

          <span class="text-base"
            >Tổng tiền: {{ appUtils.numberFormat(item.total, "VNĐ") }}</span
          >
          <span
            class="text-base"
            v-if="
              item.payment_method === PAYMENT_METHOD.points && item.voucher_info
            "
          >
            Số tiền sử dụng điểm:
            {{
              appUtils.numberFormat(
                item.voucher_info.discount_amount || 0,
                "VNĐ"
              )
            }}
          </span>
          <span
            v-if="
              item.payment_method === PAYMENT_METHOD.points && item.voucher_info
            "
            class="fs-16"
            style="color: #20409b; display: block; font-weight: bold"
            >Tổng thanh toán: {{ getTotalPriceRemaining(item) }}</span
          >
        </div>
      </div>
    </div>
    <div class="flex flex-col items-center" v-if="!convertOrders.length">
      <img
        src="/images/Page/empty.png"
        alt
        class="flex items-center mt-10"
        style="width: 80px; height: 80px"
      />
      <p class="mt-2">Không tìm thấy đơn hàng nào!</p>
    </div>
    <!-- </b-tab>
    </b-tabs>-->
  </div>
</template>

<script>
import store from "@/store";
import appUtils from "@/utils/appUtils";
import {
  INTERNAL_ORDER_STATUS,
  INTERNAL_PAYMENT_STATUS,
  PAYMENT_METHOD,
} from "@/utils/constant";
import flatPickr from "vue-flatpickr-component";

export default {
  name: "ListOrders",
  components: { flatPickr },
  data() {
    return {
      isLoading: false,
      isConflictClick: false,
      tabActive: 1,
      isShowMoreProduct: false,
      tabs: [
        {
          title: "Chưa hoàn thành",
          value: 1,
        },
        {
          title: "Đã giao hàng",
          value: 2,
        },
        {
          title: "Đã huỷ",
          value: 3,
        },
      ],
      filter: {
        keyword: "",
        order_date: "",
        status: "",
        payment_status: "",
        from_date: null,
        to_date: null,
      },
      orders: [],
      totalOrder: 0,
      PAYMENT_METHOD,
      optionsStatus: [
        {
          text: "Chọn trạng thái đơn hàng",
          value: "",
        },
        {
          text: "Chờ xử lý",
          value: INTERNAL_ORDER_STATUS.RECEIVE_ORDERS,
        },
        {
          text: "Đang xử lý",
          value: INTERNAL_ORDER_STATUS.PROCESSING,
        },
        {
          text: "Xuất kho",
          value: INTERNAL_ORDER_STATUS.SALING,
        },
        {
          text: "Giao hàng",
          value: INTERNAL_ORDER_STATUS.DELIVERABLE,
        },
        {
          text: "Hoàn thành",
          value: INTERNAL_ORDER_STATUS.FINISHED,
        },
      ],
      optionsPaymentStatus: [
        {
          text: "Chọn trạng thái thanh toán",
          value: "",
        },
        {
          text: "Chưa thanh toán",
          value: INTERNAL_PAYMENT_STATUS.UNPAID,
        },
        {
          text: "Đã thanh toán",
          value: INTERNAL_PAYMENT_STATUS.PAID,
        },
        // {
        //   text: "Đã huỷ",
        //   value: INTERNAL_PAYMENT_STATUS.CANCELLED
        // }
      ],
    };
  },
  mounted() {
    this.getListOrders();
  },
  computed: {
    convertOrders() {
      return this.orders?.map((elm) => {
        let mapTotalOfElement = elm.order_items?.map((prd) => {
          return {
            ...prd,
            totalAmount: prd.price * prd.qty,
          };
        });
        return {
          ...elm,
          firstItem: elm.order_items ? elm?.order_items[0] : {},
          moreItems: elm.order_items?.slice(1),
          total: mapTotalOfElement
            ?.map((item) => item.totalAmount)
            ?.reduce((a, b) => a + b),
        };
      });
    },
  },
  methods: {
    handleChangeTab(tab) {
      this.tabActive = tab;
    },
    handleSearch() {
      this.getListOrders();
    },
    async getListOrders() {
      this.isLoading = true;
      const params = {
        page_num: 1,
        page_size: 100,
        supplier_id: JSON.parse(localStorage.user).supplierInfo.id,
        ...this.filter,
        from_date:
          this.filter.from_date &&
          moment(this.filter.from_date).startOf("day").valueOf(),
        to_date:
          this.filter.to_date &&
          moment(this.filter.to_date).endOf("day").valueOf(),
        order: "desc",
        sort_by: "created_at",
      };
      await store
        .dispatch("app-ecommerce/fetchOrders", params)
        .then((res) => {
          this.isLoading = false;
          this.orders = res.data.data;
          this.totalOrder = res.data.page?.total;
        })
        .catch((e) => {
          this.isLoading = false;
          console.log(e);
        });
    },
    showMoreProduct(item) {
      this.isConflictClick = true;
      this.isShowMoreProduct = true;
    },
    getTotalPriceRemaining(item) {
      const price = item.total - item.voucher_info?.discount_amount;

      return appUtils.numberFormat(price < 0 ? 0 : price, "VNĐ");
    },
    getStatusByValue(value) {
      let statusInfo = {
        variant: "",
        label: "",
      };

      switch (value) {
        case INTERNAL_ORDER_STATUS.RECEIVE_ORDERS:
          statusInfo = {
            variant: "info",
            label: "Chờ xử lý",
          };
          break;
        case INTERNAL_ORDER_STATUS.PROCESSING:
          statusInfo = {
            variant: "primary",
            label: "Đang xử lý",
          };
          break;
        case INTERNAL_ORDER_STATUS.SALING:
          statusInfo = {
            variant: "dark",
            label: "Xuất kho",
          };
          break;
        case INTERNAL_ORDER_STATUS.DELIVERABLE:
          statusInfo = {
            variant: "warning",
            label: "Giao hàng",
          };
          break;
        case INTERNAL_ORDER_STATUS.FINISHED:
          statusInfo = {
            variant: "success",
            label: "Hoàn thành",
          };
          break;
        case 100:
          statusInfo = {
            variant: "danger",
            label: "Đã huỷ",
          };
          break;

        default:
          statusInfo = {
            variant: "info",
            label: "Chờ xử lý",
          };
          break;
      }

      return statusInfo;
    },
    handleGetPaymentInfo(status) {
      let paymentInfo = {
        variant: "",
        label: "",
      };

      switch (status) {
        case INTERNAL_PAYMENT_STATUS.PAID:
          paymentInfo = {
            variant: "light-success",
            label: "Đã thanh toán",
          };
          break;
        case INTERNAL_PAYMENT_STATUS.UNPAID:
          paymentInfo = {
            variant: "light-danger",
            label: "Chưa thanh toán",
          };
          break;

        default:
          break;
      }
      return paymentInfo;
    },
    goToOrderDetail(item) {
      if (this.isConflictClick) return;
      this.$router.push({
        path: `orders/${item.id}`,
      });
    },
  },

  setup() {
    return {
      appUtils,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.filter {
  display: flex;
  .filter-wrap {
    .search,
    .order-date {
      width: 280px;
    }
  }
  .filter-wrap-right {
    .order-status,
    .payment-status {
      width: 280px;
    }
  }
  @media screen and (max-width: 768px) {
    flex-direction: column;
    .filter-wrap {
      flex-direction: column;
      align-items: flex-start;
      .search,
      .order-date {
        width: 100%;
      }
    }
    .filter-wrap-right {
      .order-status,
      .payment-status {
        width: 100%;
      }
    }
  }
}
.order {
  background: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 8px;
  padding: 16px;
  color: #000;
  .order-wrapper {
    display: flex;
    align-items: flex-start;
    .product-image {
      display: flex;
      justify-content: start;
      img {
        width: 80px;
        height: fit-content;
      }
    }

    .product-information {
      flex: 2;
      .status {
        padding: 4px 16px;
        border-radius: 32px;
        font-size: 14px;
        line-height: 20px;
        background: #dbdbdb;
        width: fit-content;
      }
      .order-code {
        color: #656565;
        font-size: 12px;
        line-height: 16px;
      }
      .name {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 8px;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        display: -webkit-box;
        text-overflow: ellipsis;
      }
      .price {
        width: fit-content;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        background: #dbdbdb;
        padding: 4px 16px;
        border-radius: 12px;
        @media screen and(max-width: 768px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
  .view-more {
    color: #20419b;
    margin-bottom: 0;
    text-align: right;
    &:hover {
      text-decoration: underline;
    }
  }
  .total {
    display: flex;
    flex-direction: column;
    gap: 4px;
    justify-content: end;
    align-items: end;
    border-top: 1px solid #dbdbdb;
    padding-top: 12px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }
}
@media screen and (min-width: 768px) {
  .order-wrapper {
    align-items: center;
    .product-image {
      flex: 1;
      img {
        width: 80px !important;
      }
    }
    .product-information {
      flex: 5 !important;
      .status {
        padding: 8px 24px !important;
        font-size: 16px !important;
        line-height: 24px !important;
      }
      .order-code {
        font-size: 14px !important;
        line-height: 20px !important;
      }
      .name {
        font-size: 16px !important;
        line-height: 24px !important;
        margin-bottom: 12px;
      }
    }
  }
  .total {
    font-size: 16px !important;
    line-height: 24px !important;
  }
}
.scroll-bar {
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: rgba(241, 241, 241, 0.4);
    border-radius: 5px;
    display: none;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background: #b9b9b9;
  }

  /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  // }

  &::-webkit-scrollbar-corner {
    display: none;
  }
}
</style>