import Vue from "vue";

export default {
  namespaced: true,
  state: {
    loading_noti: false,
    hasNewMessage: false,
    notifications: [],
    notificationMeta: {
      page_num: 1,
      page_size: 10,
      total: 0,
      total_page: 0,
    },
    totalNotificationUnread: 0,
  },
  getters: {},
  mutations: {
    setHasNewMessage(state, { isNew, total }) {
      state.hasNewMessage = isNew;
      if (total) {
        state.totalNotificationUnread = total;
      }
    },
    setNotifications(state, { data, isPush }) {
      if (isPush) {
        const oldData = [...state.notifications];
        state.notifications = [...oldData, ...data];
      } else {
        state.notifications = data;
      }
    },
    setNotificationMeta(state, { metaData, count_read }) {
      state.notificationMeta = metaData;
      state.totalNotificationUnread = count_read;
    },
    setLoading(state, isLoading) {
      state.loading_noti = isLoading;
    },
  },
  actions: {
    getNotifications(ctx, { params, isPush }) {
      ctx.commit("setLoading", true);

      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .getNotifications(params)
          .then((result) => {
            ctx.commit("setNotifications", {
              data: result.data?.data || [],
              isPush,
            });
            ctx.commit("setNotificationMeta", {
              metaData: result.data?.page || {
                page_num: 1,
                page_size: 10,
                total: 0,
                total_page: 0,
              },
              count_read: result.data?.count_unread || 0,
            });

            resolve(result);
          })
          .catch((error) => {
            reject(error.message);
          })
          .finally(() => {
            ctx.commit("setLoading", false);
          });
      });
    },
    updateReadNotifications(ctx, { params, callback }) {
      ctx.commit("setLoading", true);

      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("CommonRequest")
          .putUpdateReadNotifications(params)
          .then((result) => {
            callback();
            resolve(result);
          })
          .catch((error) => {
            reject(error.message);
          })
          .finally(() => {
            ctx.commit("setLoading", false);
          });
      });
    },
  },
};
