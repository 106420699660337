<template>
  <b-tabs content-class="m-1" nav-class="border-bottom nav-padding">
    <b-tab
      v-for="tab in tabs"
      :key="tab.title"
      :title="tab.title"
      @click="fetchPointHistory(tab.type)"
    >
      <div
        v-if="!Object.keys(pointHistory).length"
        class="flex justify-between items-center"
      >
        <p v-if="activeTab.title" class="mt-2 mx-auto">
          Chưa có giao dịch {{ activeTab.title.toLowerCase() }} nào
        </p>
      </div>
      <div v-for="date in Object.keys(pointHistory)" :key="date">
        <p class="mt-2 mb-0 text-center">{{ date }}</p>
        <div
          v-for="transaction in pointHistory[date]"
          :key="transaction.id"
          class="d-flex justify-between items-center border-bottom"
        >
          <div>
            <b class="my-1 d-block" style="color: black">{{
              getTransactionType(transaction.type)
            }}</b>
            <p class="mb-1">
              Vào lúc {{ moment(transaction.created_at).format("HH:mm")
              }}<span
                v-if="transaction.type === HISTORY_TYPE.PLUS_POINT"
                class="text-primary"
                >{{ " bởi " + transaction.create_by_user.name }}</span
              >
            </p>
            <p
              v-if="transaction.type !== HISTORY_TYPE.PLUS_POINT"
              class="mb-1"
              style="color: #0c0c0d"
            >
              Đơn hàng {{ transaction.order_code }}
            </p>
            <!-- <p v-else class="mb-1 text-primary">Đơn hàng {{ transaction.order_code }}</p> -->
          </div>
          <div
            :class="
              transaction.type === HISTORY_TYPE.SPEND_POINT
                ? 'text-warning'
                : 'text-success'
            "
          >
            <strong class="mr-1"
              >{{ transaction.type === HISTORY_TYPE.SPEND_POINT ? "-" : "+" }}
              {{ appUtils.numberFormat(transaction.point_use) }}</strong
            >
          </div>
        </div>
      </div>
    </b-tab>
  </b-tabs>
</template>

<script>
import appUtils from "@/utils/appUtils";
import { DEPOSIT_SCOPE } from "@/utils/constant";

export default {
  name: "PointHistory",
  data() {
    const HISTORY_TYPE = {
      ALL: 0,
      SAVE_POINT: 1,
      SPEND_POINT: 2,
      PLUS_POINT: 4,
      RE_POINT: 7,
    };
    return {
      tabs: [
        {
          title: "Tất cả",
          type: HISTORY_TYPE.ALL,
        },
        // {
        //   title: "Tích điểm",
        //   type: HISTORY_TYPE.SAVE_POINT,
        // },
        {
          title: "Tiêu điểm",
          type: HISTORY_TYPE.SPEND_POINT,
        },
        {
          title: "Nạp điểm",
          type: HISTORY_TYPE.PLUS_POINT,
        },
      ],
      activeTab: {},
      pointHistory: [],
      HISTORY_TYPE: HISTORY_TYPE,
      appUtils,
    };
  },
  async created() {
    await this.fetchPointHistory();
  },
  methods: {
    async fetchPointHistory(selectedType = this.tabs[0].type) {
      let params = {
        page_num: 1,
        page_size: 100,
        org_id: this.$user?.supplierInfo?.org_id,
        type: selectedType,
        sort_by: "created_at",
        order: "desc",
        scope: this.$user?.supplierInfo?.workspace_id
          ? DEPOSIT_SCOPE.ws
          : DEPOSIT_SCOPE.org,
        scope_id:
          this.$user?.supplierInfo?.workspace_id ||
          this.$user?.supplierInfo?.org_id,
      };

      if (selectedType === this.HISTORY_TYPE.ALL) {
        params.type = `${this.HISTORY_TYPE.SPEND_POINT},${this.HISTORY_TYPE.PLUS_POINT},${this.HISTORY_TYPE.RE_POINT}`;
      }
      await this.$rf
        .getRequest("EcomRequest")
        .getPointHistory(params)
        .then((res) => {
          this.activeTab = this.tabs.find((tab) => tab.type === selectedType);
          this.pointHistory = this.groupArrayByDate(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    groupArrayByDate(objects) {
      const groupedObjects = objects.reduce((grouped, object) => {
        const { created_at } = object;
        const today = this.moment(new Date()).format("DD/MM/yyyy");
        const date = this.moment(new Date(created_at)).format("DD/MM/yyyy");
        const key = date === today ? "HÔM NAY" : "NGÀY " + date;
        if (!grouped[key]) {
          grouped[key] = [];
        }
        grouped[key].push(object);
        return grouped;
      }, {});

      return groupedObjects;
    },
    getTransactionType(type) {
      switch (type) {
        case this.HISTORY_TYPE.SAVE_POINT:
          return "Mua hàng tích điểm";
        case this.HISTORY_TYPE.SPEND_POINT:
          return "Tiêu điểm";
        case this.HISTORY_TYPE.PLUS_POINT:
          return "Nạp điểm";
        case this.HISTORY_TYPE.RE_POINT:
          return "Hoàn điểm";
        default:
          break;
      }
    },
  },
};
</script>

<style>
.nav-padding {
  padding: 0.25rem 0.75rem 0;
}
</style>