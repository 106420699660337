import Vue from "vue"
import VueRouter from "vue-router"
import marketplaceRoutes from "./marketplaceRoutes"
import defaultRoutes from "./routes/default"
import ecom from "./routes/ecom"

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    // { path: "/", redirect: { name: "home" } },
    { path: "/", redirect: { name: "Marketplace" } },
    ...defaultRoutes,
    ...ecom,
    ...marketplaceRoutes,
    {
      path: "*",
      redirect: "error-404",
    },
  ],
})

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index]
  if (!subsequentMiddleware) return context.next
  return (...parameters) => {
    context.next(...parameters)
    const nextMiddleware = nextFactory(context, middleware, index + 1)
    subsequentMiddleware({
      ...context,
      next: nextMiddleware,
    })
  }
}

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware]
    const context = {
      from,
      next,
      router,
      to,
    }
    const nextMiddleware = nextFactory(context, middleware, 1)
    return middleware[0]({
      ...context,
      next: nextMiddleware,
    })
  }

  return next()
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg")
  if (appLoading) {
    appLoading.style.display = "none"
  }
})

export default router
