
export default {
  namespaced: true,
  state: {
    currLanguage: ''
  },
  getters: {
    //
  },
  mutations: {
    SET_CURRENT_LANGUAGE(state, val) {
      state.currLanguage = val
    }
  },
  actions: {},
}
