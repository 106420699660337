import auth from "@/middleware/auth"

export default [
  {
    path: "/marketplace",
    meta: {
      middleware: [auth]
    },
    name: "Marketplace",
    component: () =>
      import("@/views/pages/marketplace/marketplace-shop/Marketplace.vue"),
  },
  {
    path: "/marketplace/:slug",
    meta: {
      middleware: [auth]
    },
    name: "ProductDetail",
    component: () => import("@/pages/Ecom/ProductInfo.vue"),
  },
  {
    path: "/shopping-cart",
    meta: {
      middleware: [auth]
    },
    name: "ShoppingCart",
    component: () => import("../pages/Ecom/ShoppingCart.vue"),
  },
  {
    path: "/payment/order",
    meta: {
      middleware: [auth]
    },
    name: "OrderConfirm",
    component: () => import("../pages/Ecom/OrderConfirm.vue"),
  },
  {
    path: "/orders",
    meta: {
      middleware: [auth]
    },
    name: "ListOrders",
    component: () => import("../pages/Ecom/ListOrders.vue"),
  },
  {
    path: "/orders/:id",
    meta: {
      middleware: [auth]
    },
    name: "OrderDetail",
    component: () => import("../pages/Ecom/OrderDetail.vue"),
  },
  {
    path: "/user-profile",
    meta: {
      middleware: [auth]
    },
    name: "UserProfile",
    component: () => import("../pages/User/UserProfile.vue"),
  },
  // {
  //   path: "/payment-response/:id",
  //   meta: {
  //     middleware: [auth]
  //   },
  //   name: "PaymentResponse",
  //   component: () => import("../pages/User/UserProfile.vue"),
  // },
  // {
  //   // path: '/payment/:id(\\d+)/order',
  //   path: '/payment/order',
  //   meta: { layout: 'guest' },
  //   name: 'OrderConfirm',
  //   component: () => import('../pages/Ecom/OrderConfirm.vue')
  //   // component: () => {}
  // },
  // {
  //   path: '/payment/:id(\\d+)/invoice',
  //   meta: { layout: 'guest' },
  //   name: 'ProductOrderInvoice',
  //   component: () => import('../pages/Invoices/ProductOrderInvoice.vue')
  //   // component: () => {}
  // }
]
