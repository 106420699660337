<template>
  <b-modal
    id="modal-add-new"
    title="Thêm địa chỉ mới"
    ref="modal-add-new"
    modal-class="modal-add-new"
    @show="handleModalShow"
    @hidden="resetModal(); $emit('hidden')"
    @ok="handleOk"
    size="lg"
  >
    <validation-observer ref="formValidation">
      <b-form ref="form" @submit.stop.prevent="handleSubmit">
        <b-form-group label="Họ và tên" label-class="required" label-for="name-input">
          <validation-provider #default="validationContext" name="Họ và tên" rules="required">
            <b-form-input
              id="name-input"
              v-model="form.name"
              :state="getValidationState(validationContext)"
              required trim
            ></b-form-input>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Số điện thoại" label-for="phone-input" label-class="required">
          <validation-provider
            #default="validationContext"
            name="Số điện thoại"
            rules="required|integer"
          >
            <b-form-input
              id="phone-input"
              v-model="form.phone"
              :state="getValidationState(validationContext)"
              required trim
            ></b-form-input>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group
          label="Tỉnh, thành phố, quận/ huyện"
          label-for="city-input"
        >
          <b-form-input
            id="city-input"
            v-model="form.city"
            trim
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Địa chỉ cụ thể" label-for="address-input" label-class="required">
          <validation-provider #default="validationContext" name="Địa chỉ cụ thể" rules="required">
            <b-form-input
              id="address-input"
              v-model="form.address"
              :state="getValidationState(validationContext)"
              required trim
            ></b-form-input>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-group label="Email" label-for="email-input" label-class="required">
          <validation-provider #default="validationContext" name="Email" rules="required|email">
            <b-form-input
              id="email-input"
              v-model="form.email"
              :state="getValidationState(validationContext)"
              required trim
            ></b-form-input>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
        <b-form-checkbox
          id="checkbox-1"
          v-model="form.default"
          name="checkbox-1"
          :value="2"
          :unchecked-value="1"
        >Đây là địa chỉ mặc định</b-form-checkbox>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { email, required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  name: 'ModalAddNewAddress',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  props: ['addressId', 'route'],
  data() {
    return {
      form: {
        name: '',
        phone: null,
        address: '',
        email: '',
        city: '',
        default: 1
      },
      required,
      email,
      formValidation
    }
  },
  computed: {},
  methods: {
    async handleModalShow() {
      if (this.addressId) {
        await this.$rf
          .getRequest('EcomRequest')
          .getAddressById(this.addressId)
          .then(res => {
            this.form = {
              name: res.data.name,
              phone: res.data.phone,
              address: res.data.address,
              email: res.data.email,
              city: res.data.city,
              default: res.data.default
            }
          })
          .catch(err => {
            console.log(err)
            this.$message({
              message: 'Có lỗi xảy ra, vui lòng thử lại sau',
              type: 'error'
            })
          })
      }
    },
    async createNewAddress() {
      await this.$rf
        .getRequest('EcomRequest')
        .createAddress(this.form)
        .then(res => {
          this.$message({
            message: 'Thêm mới địa chỉ thành công',
            type: 'success'
          })
          this.$emit('refresh')

          this.$nextTick(() => {
            this.$bvModal.hide('modal-add-new')
            if (this.route === 'confirm') this.$emit('create', res.data)
          })
        })
        .catch(err => {
          console.log(err)
          this.$message({
            message: 'Có lỗi xảy ra, vui lòng thử lại sau',
            type: 'error'
          })
        })
    },
    async editAddress() {
      await this.$rf
        .getRequest('EcomRequest')
        .updateAddress(this.addressId, this.form)
        .then(res => {
          this.$message({
            message: 'Sửa đổi địa chỉ thành công',
            type: 'success'
          })
          this.$emit('refresh')

          this.$nextTick(() => {
            this.$bvModal.hide('modal-add-new')
          })
        })
        .catch(err => {
          console.log(err)
          this.$message({
            message: 'Có lỗi xảy ra, vui lòng thử lại sau',
            type: 'error'
          })
        })
    },
    getValidationState(errors) {
      const { getValidationState } = this.formValidation(() => {})
      return getValidationState(errors)
    },
    resetModal() {
      this.form = {
        name: '',
        phone: null,
        address: '',
        email: '',
        city: '',
        default: 1
      }
    },
    handleOk(e) {
      e.preventDefault()
      this.handleSubmit()
    },
    handleSubmit() {
      this.$refs.formValidation.validate().then(async success => {
        if (success) {
          if (!this.addressId) {
            await this.createNewAddress()
          } else {
            await this.editAddress()
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>

<style lang="scss" >
.modal-add-new {
  .modal-dialog {
    margin-top: 100px;
  }
}
</style>