import BaseRequest from "../BaseRequest";
import appUtils from "../../utils/appUtils";

export default class AuthRequest extends BaseRequest {
  login(data = {}) {
    return this.post("auth/login", data);
  }

  logout() {
    return this.post("auth/logout");
  }

  me() {
    try {
      // return this.get('auth/me')
      // return this.get('auth/doctor/me')
      return this.get("auth/doctor/v2/me");
    } catch (e) {
      appUtils.removeLocalUser();
      appUtils.removeLocalToken();

      return window.$router.push({ path: "/login" });
    }
  }
  goMe() {
    return this.get("go/core/auth/doctor/me");
  }
  updateTokenDevices(params) {
    return this.postLocal("go/core/devices", params);
  }
  deleteTokenDevices(id) {
    return this.deleteLocal(`go/core/devices/${id}`);
  }
}
