<template>
  <div>
    <div class="border-bottom">
      <div class="m-1 flex justify-between items-center">
        <h4 class="mb-0 font-weight-bold" style="color: #000000d9">
          Địa chỉ nhận hàng
        </h4>
        <b-button variant="primary" v-b-modal.modal-add-new>
          Thêm mới
          <svg
            style="transform: translate(5px, -1px)"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 6C12 6.13261 11.9473 6.25979 11.8536 6.35355C11.7598 6.44732 11.6326 6.5 11.5 6.5H6.5V11.5C6.5 11.6326 6.44732 11.7598 6.35355 11.8536C6.25979 11.9473 6.13261 12 6 12C5.86739 12 5.74021 11.9473 5.64645 11.8536C5.55268 11.7598 5.5 11.6326 5.5 11.5V6.5H0.5C0.367392 6.5 0.240215 6.44732 0.146447 6.35355C0.0526785 6.25979 0 6.13261 0 6C0 5.86739 0.0526785 5.74021 0.146447 5.64645C0.240215 5.55268 0.367392 5.5 0.5 5.5H5.5V0.5C5.5 0.367392 5.55268 0.240215 5.64645 0.146447C5.74021 0.0526785 5.86739 0 6 0C6.13261 0 6.25979 0.0526785 6.35355 0.146447C6.44732 0.240215 6.5 0.367392 6.5 0.5V5.5H11.5C11.6326 5.5 11.7598 5.55268 11.8536 5.64645C11.9473 5.74021 12 5.86739 12 6Z"
              fill="white"
            />
          </svg>
        </b-button>
      </div>
    </div>
    <b-overlay :show="loading" rounded="sm">
      <div v-if="!addressList.length" class="flex justify-between items-center">
        <p class="mt-2 mx-auto">Chưa có địa chỉ nào được lưu</p>
      </div>
      <!-- <div v-else class="address-list scroll-bar" 
        v-infinite-scroll="nextSearchPage"
      >-->
      <div v-else class="address-list scroll-bar">
        <div
          v-for="add in addressList"
          :key="add.id"
          class="border-bottom flex justify-between items-center"
        >
          <div class="m-1">
            <p class="text-dark" style="font-weight: 700; margin-bottom: 7px">
              {{ add.name }}
            </p>
            <p style="margin-bottom: 7px">{{ add.phone | formatPhone }}</p>
            <p style="margin-bottom: 7px">
              {{ add.address + (add.city ? ", " + add.city : "") }}
            </p>
            <b-badge
              v-if="add.default === 2"
              variant="info"
              style="font-weight: 700"
              >Mặc định</b-badge
            >
          </div>
          <div>
            <p
              v-b-modal.modal-add-new
              @click="selectingAddressId = add.id"
              class="font-weight-bold mr-2 text-primary cursor-pointer text-center"
              style="margin-bottom: 7px"
            >
              Chỉnh sửa
            </p>
            <p
              v-b-modal.modal-delete
              @click="selectingAddressId = add.id"
              class="font-weight-bold mb-0 mr-2 text-danger cursor-pointer text-center"
            >
              Xóa
            </p>
          </div>
        </div>
      </div>
    </b-overlay>
    <ModalAddNewAddress
      :addressId="selectingAddressId"
      route
      @refresh="refresh"
      @hidden="selectingAddressId = null"
    ></ModalAddNewAddress>
    <b-modal
      id="modal-delete"
      centered
      button-size="sm"
      ok-title="Yes"
      @ok="deleteAddress(selectingAddressId)"
      @hidden="selectingAddressId = null"
    >
      <div class="d-block text-center">
        <h3>Bạn có chắc chắn muốn xóa địa chỉ này?!</h3>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ModalAddNewAddress from "./ModalAddNewAddress.vue";

export default {
  name: "UserAdress",
  components: { ModalAddNewAddress },
  data() {
    return {
      addressList: [],
      selectingAddressId: null,
      loading: false,
    };
  },
  async created() {
    await this.fetchAddressList();
  },
  computed: {
    ...mapState({
      userLogin: (state) => state.authenticate.userLogin,
    }),
  },
  methods: {
    async fetchAddressList() {
      const params = {
        keyword: "",
        user_id: this.userLogin?.id,
        sort_by: "",
        order: "",
        page_num: 1,
        page_size: 10,
      };
      this.loading = true;
      await this.$rf
        .getRequest("EcomRequest")
        .getAddressListOfUser(params)
        .then((res) => {
          this.addressList = res.data.data;
          this.loading = false;
        });
    },
    async deleteAddress(id) {
      this.loading = true;
      await this.$rf
        .getRequest("EcomRequest")
        .deleteAddress(id)
        .then(async (res) => {
          await this.refresh();
          this.$message({
            message: "Xóa địa chỉ thành công",
            type: "success",
          });
        })
        .catch((err) => {
          this.$message({
            message: "Xóa địa chỉ thất bại",
            type: "error",
          });
        });
      this.loading = false;
    },
    async refresh() {
      await this.fetchAddressList();
    },
  },
  filters: {
    formatPhone(phone) {
      return "(+84) " + (phone[0] === "0" ? phone.substring(1) : phone);
    },
  },
};
</script>

<style lang="scss" scoped>
.address-list {
  max-height: calc(100vh - 330px);
  overflow-y: scroll;
}
.scroll-bar {
  /* width */
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: rgba(241, 241, 241, 0.4);
    border-radius: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    border-radius: 0.5rem;
    background: #b9b9b9;
  }

  /* Handle on hover */
  // ::-webkit-scrollbar-thumb:hover {
  // }

  &::-webkit-scrollbar-corner {
    display: none;
  }
}
</style>