import store from '@/store/index';
import appUtils from '@/utils/appUtils';
import Vue from 'vue';

export default async function ({ to, from, next }) {
  const token = appUtils.getLocalToken();
  const tokenExpiration = appUtils.getLocalTokenExpiration();

  if (new Date().getTime() > tokenExpiration || !token || !store?.state?.authenticate?.token) {
    const res = await store.dispatch('authenticate/initAuth')
    if (!res || !store?.state?.authenticate?.token) {
      // console.log(to, from)
      Vue.prototype.$targetRoute = to
      return next({ name: 'welcome' })
    }
  }
  return next()
}
