import BaseRequest from "../BaseRequest";

export default class CommonRequest extends BaseRequest {
  // Upload file to server
  uploadFileTenant(params) {
    return this.postLocal(`go/core/files/requestUpload`, params);
  }
  // Categories
  getCategories(params) {
    return this.getLocal(`go/partner/products/categories`, params);
  }
  // Units
  getUnits(params) {
    return this.getLocal(`go/partner/products/units`, params);
  }
  // Variants
  deleteVariant(id) {
    return this.deleteLocal(`go/partner/products/variant/${id}`);
  }
  // Brands
  getBrands(params) {
    return this.getLocal(`go/partner/products/brands`, params);
  }
  // Delivery Status Configs
  getDeliveryStatusConfigs(params) {
    // Get all data and no paging
    return this.getLocal(`go/partner/product/delivery_configs`, params);
  }
  getDeliveryStatusConfigsListWithPaging(params) {
    // Get data with paging
    return this.getLocal(`go/partner/product/delivery_configs/list`, params);
  }
  getDeliveryStatusConfigsById(id, params) {
    return this.getLocal(`go/partner/product/delivery_configs/${id}`, params);
  }
  createNewDeliveryStatusConfig(data) {
    return this.postLocal(`go/partner/product/delivery_configs/create`, data);
  }
  updateDeliveryStatusConfigsById(id, data) {
    return this.putLocal(`go/partner/product/delivery_configs/${id}`, data);
  }
  deleteDeliveryStatusConfigsById(id) {
    return this.deleteLocal(`go/partner/product/delivery_configs/${id}`);
  }
  // Delivery Definition Configs
  getDeliveryDefinitionConfigs(params) {
    // Get all data and no paging
    return this.getLocal(`go/partner/product/attachment_configs`, params);
  }
  getDeliveryDefinitionConfigsListWithPaging(params) {
    // Get data with paging
    return this.getLocal(`go/partner/product/attachment_configs/list`, params);
  }
  getDefinitionConfigsById(id, params) {
    return this.getLocal(`go/partner/product/attachment_configs/${id}`, params);
  }
  createNewDefinitionConfigs(data) {
    return this.postLocal(`go/partner/product/attachment_configs/create`, data);
  }
  updateDefinitionConfigsById(id, data) {
    return this.putLocal(`go/partner/product/attachment_configs/${id}`, data);
  }
  deleteDefinitionConfigsById(id) {
    return this.deleteLocal(`go/partner/product/attachment_configs/${id}`);
  }
  // Products
  getProducts(params) {
    return this.getLocal(`go/partner/products`, params);
  }
  getProductById(id) {
    return this.getLocal(`go/partner/products/${id}`);
  }
  addProduct(data) {
    return this.postLocal(`go/partner/products/create`, data);
  }
  updateProduct(id, data) {
    return this.putLocal(`go/partner/products/update/${id}`, data);
  }
  deleteProduct(id) {
    return this.deleteLocal("go/partner/products/" + id);
  }
  // Product Certificates
  getProductCertificates(params) {
    return this.getLocal(`go/core/partner/product/cer/upload/list`, params);
  }
  updateProductCertificateItem(id, data) {
    return this.putLocal(`go/core/partner/product/cer/${id}`, data);
  }
  //Order
  getOrders(params) {
    return this.getLocal(`go/partner/products/supplierOrders`, params);
  }
  getDetailOrder(id) {
    return this.getLocal(`go/core/partner/supplierOrder/${id}`);
  }
  postUpdateStatusOrder(params) {
    return this.postLocal(`go/core/partner/supplierOrder/status`, params);
  }
  postUpdateAttachmentResult(params) {
    return this.postLocal(
      `go/core/partner/supplierOrder/attachmentResult`,
      params
    );
  }
  approveAttachmentResult(id) {
    return this.postLocal(
      `go/core/partner/supplierOrder/attachmentResult/approve/${id}`
    );
  }
  postOrderToAssignedProcessor(params) {
    return this.postLocal(`go/partner/processors/assign`, params);
  }

  //Product inventory
  getProductInventory(params) {
    return this.getLocal(`go/core/partner/inventory`, params);
  }
  addProductIntoInventory(params) {
    return this.postLocal(`go/core/partner/inventory`, params);
  }
  postUpdateProductStatus(id, params) {
    return this.postLocal(`go/core/partner/inventory/${id}`, params);
  }
  getOrderFormConfigs(params) {
    return this.getLocal(`go/partner/product/order_form_configs`, params);
  }

  //Reports
  getReports(params) {
    return this.getLocal(`go/core/report/productsale`, params);
  }
  getSaleReport(params) {
    return this.getLocal("go/core/report/supplierorders", params);
  }
  putOrderFormItem(id, params) {
    return this.putLocal(`go/orders/products/order_form_items/${id}`, params);
  }

  //Processors
  getProcessors(params) {
    return this.getLocal(`go/partner/processors`, params);
  }

  //Order processor
  getOrdersProcessor(params) {
    return this.getLocal(`go/partner/processors/orders`, params);
  }
  getDetailOrderProcessor(id) {
    return this.getLocal(`go/partner/processors/orders/${id}`);
  }
  postUpdateStatusOrderProcessor(params, id) {
    return this.putLocal(
      `go/partner/processors/orders/change_state/${id}`,
      params
    );
  }

  printDocument(params) {
    return this.post("doctor/emr-documents/printing-html", params);
  }

  updateOrderItemResultUrl(id, params) {
    return this.putLocal(`go/partner/products/order_items/${id}`, params);
  }
  putCancelOrder(id, params) {
    return this.putLocal(`go/partner/products/cancel/${id}`, params);
  }
  postDraftOrderForm(params) {
    return this.postLocal("go/partner/order_form/drafts", params);
  }
  getDraftOrderForm(params) {
    return this.getLocal("go/partner/order_form/drafts", params);
  }
  getNotifications(params) {
    return this.get("go/partner/products/ecom_notifies", params);
  }
  putUpdateReadNotifications(params) {
    return this.putLocal("go/partner/products/ecom_notifies/mark_read", params);
  }
  getConsentForm(params) {
    return this.getLocal("go/organization_consentform", params);
  }
  postComment(params) {
    return this.postLocal("go/ecom_comments/create", params);
  }
  getComments(params) {
    return this.getLocal("go/ecom_comments", params);
  }
  markNewCommentsAsRead(params) {
    return this.putLocal("go/ecom_comments/markread", params);
  }
  getSupplierInfo() {
    return this.getLocal(`go/ecom/supplier`)
  }
}
