import {
  HODOCONFIG_FIREBASE,
  MESSAGE_CODE_LOGIN_ERROR,
} from "@/utils/constant";
import { getMessaging, getToken } from "firebase/messaging";

import store from "@/store";
import appUtils from "@/utils/appUtils";
import { initializeApp } from "firebase/app";
import Vue from "vue";

// import axios from 'axios'
// eslint-disable-next-line import/no-cycle
// import axios from "@/libs/axios";

export function registerFirebase() {
  const userData = appUtils.getLocalUser();
  if (userData) {
    if ("serviceWorker" in navigator && "PushManager" in window) {
      console.log("Service Worker and Push is supported");

      navigator.serviceWorker
        .register("firebase-messaging-sw.js?v=1605238", {
          useCache: false,
        })
        .catch(function (error) {
          console.error("Service Worker Error", error);
        })
        .then((subscription) => {
          var firebaseConfig = {
            apiKey: "AIzaSyDvMMgJWMS8vgtfslZJ56byo3akYbfupZM",
            authDomain: "hodo-46abc.firebaseapp.com",
            databaseURL: "https://hodo-46abc.firebaseio.com",
            projectId: "hodo-46abc",
            storageBucket: "hodo-46abc.appspot.com",
            messagingSenderId: "923239105015",
            appId: "1:923239105015:web:c8958b39259abffa15eef2",
            measurementId: "G-Y47WD5M5E1",
          };

          initializeApp(firebaseConfig);
          const messaging = getMessaging();

          return getToken(messaging, {
            vapidKey: HODOCONFIG_FIREBASE.firebaseKey,
          })
            .then((currentToken) => {
              if (currentToken) {
                // Send the token to your server and update the UI if necessary
                console.log("Token is:", currentToken);
                store.dispatch(
                  "authenticate/handleUpdateTokenDevices",
                  currentToken
                );

                return currentToken;
                // ...
              } else {
                // Show permission request UI
                console.log(
                  "No registration token available. Request permission to generate one."
                );
                // ...
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token. ", err);
              // ...
            });
          //   onMessage(messaging, (payload) => {
          //   console.log('Message received. ', payload);

          // });
        })
        .then((token) => {
          console.log("Receive message");
          return token;
        });
    } else {
      console.warn("Push messaging is not supported");
    }
  }
}

function clearCookie(name, domain, path) {
  var domain = domain || document.domain;
  var path = path || "/";
  document.cookie = `${name}=; expires=${+new Date()}; domain=${domain}; path=${path}`;
}

export default {
  namespaced: true,
  state: {
    token: null,
    permissions: [],
    userLogin: {},
    regionsAcept: "",
    regions: "",
    is_test_provider: 1,
    tokenFirebase: "",
    deviceInfo: {},
    supplierArr: []
  },
  getters: {
    isAuthenticated(state) {
      return state.token != null;
    },
    userLogin(state) {
      return state.userLogin;
    },
    regions(state) {
      return state.regions;
    },
    regionsAcept(state) {
      return state.regionsAcept;
    },
  },
  mutations: {
    clearToken(state) {
      console.log("clear")
      state.token = null;
      state.tokenFirebase = null;
      state.deviceInfo = {};
    },
    setRegionsAcept(state, regionsAcept) {
      state.regionsAcept = regionsAcept;
    },
    setRegions(state, regions) {
      state.regions = regions;
    },
    setUserLogin(state, userLogin) {
      state.userLogin = userLogin;
    },
    setToken(state, token) {
      state.token = token;
    },
    setPermissions(state, permissions) {
      state.permissions = permissions;
    },
    clearPermissions(state) {
      state.permissions = [];
    },
    setIsTestProvider(state, data) {
      return (state.is_test_provider = data);
    },
    setTokenFirebase(state, token) {
      return (state.tokenFirebase = token);
    },
    setDeviceInfo(state, data) {
      state.deviceInfo = data;
    },
    setSupplierArr(state, data) {
      state.supplierArr = data || [];
    }
  },
  actions: {
    login(ctx, credentials) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest("AuthRequest")
          .login(credentials)
          .then(async (result) => {
            if (result?.code === 200) {
              const res = result.data;
              appUtils.setLocalToken(res?.access_token);
              appUtils.setLocalTokenExpiration(
                new Date().getTime() + 3600 * 1000 * 24
              );
              ctx.dispatch("setLogoutTimer", 3600 * 1000 * 24);
              ctx.commit("setToken", res?.access_token);
              await ctx.dispatch("authMe");
            }
            resolve({
              success: true,
            });
          })
          .catch((error) => {
            console.log(error);
            reject(error.message);
          });
      });
    },

    async authMe(ctx) {
      return await new Promise(async (resolve, reject) => {
        await Vue.prototype.$rf
          .getRequest("AuthRequest")
          .goMe()
          .then(async (result) => {
            if (result?.status === 200) {
              const res = result.data?.data || {};
              const org_id = appUtils.getOrgId()
              const ws_id = appUtils.getWsId()
              console.log({ ws_id, org_id })

              const supplierInfo = await ctx.dispatch("handleGetSupplierInfo", { ws_id, org_id }) || {}
              const newUserInfo = {
                ...res,
                supplierInfo: supplierInfo,
              };

              // const isOwnerOrAdmin = [1, 2].includes(
              //   newUserInfo?.supplierInfo?.role ||
              //   newUserInfo?.processorsInfo?.role
              // );
              if (!supplierInfo) {
                ctx.commit("clearToken");
                appUtils.removeAll();
                reject({
                  message: MESSAGE_CODE_LOGIN_ERROR,
                });

                // return;
              } else {
                appUtils.setLocalUser(JSON.stringify(newUserInfo));
                Vue.prototype.$user = { ...newUserInfo }
                ctx.commit("setUserLogin", newUserInfo);
                // ctx.dispatch("updateTokenDevices");
                resolve({
                  success: true,
                });
              }
            }
          })
          .catch((error) => {
            reject(error.message);
          });
      });
    },

    async initAuth(ctx) {
      const token = appUtils.getLocalToken();
      const tokenExpiration = appUtils.getLocalTokenExpiration();

      if (!token || !tokenExpiration) {
        ctx.dispatch("logout");
        return false;
      }

      if (new Date().getTime() > tokenExpiration || !token) {
        ctx.dispatch("logout");
        return false;
      }

      ctx.commit("setToken", token);
      ctx.dispatch("setLogoutTimer", tokenExpiration - new Date().getTime());
      await ctx.dispatch("authMe");
      return true;
    },
    setLogoutTimer(ctx, duration) {
      setTimeout(() => {
        ctx.commit("clearToken");
      }, duration);
    },
    async logout(ctx, callback) {
      const deviceId = ctx.state.deviceInfo?.ID;

      if (deviceId) {
        await Vue.prototype.$rf
          .getRequest("AuthRequest")
          .deleteTokenDevices(deviceId)
          .then(() => { });
      }

      ctx.commit("clearToken");
      localStorage.removeItem("deviceId");
      appUtils.removeAll();

      try {
        callback && callback()
      } catch (error) { }
    },
    handleUpdateTokenDevices(ctx, token) {
      let deviceId = localStorage.getItem("deviceId");
      let uuid = "";
      if (!deviceId) {
        uuid = appUtils.uuid();
        localStorage.setItem("deviceId", uuid);
      }

      ctx.commit("setTokenFirebase", token);
      const params = {
        device_id: uuid,
        device_token: token,
        device_type: "web",
        user_id: ctx.state.userLogin?.id,
      };

      Vue.prototype.$rf
        .getRequest("AuthRequest")
        .updateTokenDevices(params)
        .then((res) => {
          const deviceInfo = res?.data?.device;
          if (!deviceInfo) return;

          ctx.commit("setDeviceInfo", deviceInfo);
        });
    },
    async handleGetSupplierInfo(ctx, { ws_id, org_id }) {

      if (!org_id && !ws_id) {
        ctx.commit("clearToken");
        appUtils.removeAll();
        return
      }


      try {
        const response = await Vue.prototype.$rf.getRequest('CommonRequest').getSupplierInfo()
        if (response.status === 200) {
          const supplierWs = response.data?.find(item => item.workspace_id === ws_id)
          const supplierOrg = response.data?.find(item => item.org_id === org_id)
          ctx.commit("setSupplierArr", response.data)
          return supplierWs || supplierOrg
        } else {
          ctx.commit("clearToken");
          appUtils.removeAll();
          return
        }

      } catch (error) {
        console.log(error)

        ctx.commit("clearToken");
        localStorage.removeItem("deviceId");
        appUtils.removeAll();
      }
    }
  },
};
