import * as rules from "vee-validate/dist/rules";

import Vue from "vue";
import VueI18n from "vue-i18n";
import { extend } from "vee-validate";
import { messages as msgEn } from "vee-validate/dist/locale/en.json";
import { messages as msgVi } from "vee-validate/dist/locale/vi.json";

Vue.use(VueI18n);
function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const i18n = new VueI18n({
  locale: "vi",
  fallbackLocale: "vi",
  messages: loadLocaleMessages(),
});

Vue.prototype.$getCalendarLabel = (locale) => {
  const calendarLabel = {
    vi: {
      labelPrevDecade: 'Thập kỷ trước',
      labelPrevYear: 'Năm trước',
      labelPrevMonth: 'Tháng trước',
      labelCurrentMonth: 'Tháng này',
      labelNextMonth: 'Tháng sau',
      labelNextYear: 'Năm sau',
      labelNextDecade: 'Thập kỉ sau',
      labelToday: 'Hôm nay',
      labelSelected: 'Đã chọn',
      labelNoDateSelected: 'Chưa chọn ngày',
      labelCalendar: 'Lịch',
      labelNav: 'Chọn ngày',
      labelHelp: 'Sử dụng phím mũi tên để thay đổi ngày',
      labelResetButton: 'Xóa',
      labelTodayButton: 'Chọn hôm nay',
    },
  }
  return calendarLabel[locale] || {}
}

Object.keys(rules).forEach((rule) => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message:
      i18n.locale === "vi"
        ? msgVi[rule]
        : i18n.locale === "en"
        ? msgEn[rule]
        : [], // assign message
  });
});

export default i18n;
