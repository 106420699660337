import "@/@fake-db/db";
import "./plugins";
// Global Components
import "@/global-components";
// 3rd party plugins
import "@/libs/acl";
import "@/libs/clipboard";
import "@/libs/portal-vue";
import "@/libs/sweet-alerts";
import "@/libs/toastification";
import "@/libs/tour";
import "@/libs/vue-select";
import "@axios";
// Axios Mock Adapter
import "@/@fake-db/db";

import BootstrapVue, {
  IconsPlugin,
  ModalPlugin,
  ToastPlugin,
  VBTooltipPlugin,
} from "bootstrap-vue";

import App from "@/App.vue";
import RequestFactory from "@/api/RequestFactory";
import i18n from "@/libs/i18n";
import router from "@/router";
import store from "@/store";
import { ROLES_SYSTEM } from "@/utils/constant";
import VueCompositionAPI from "@vue/composition-api";
import moment from "moment";
import Vue from "vue";
import infiniteScroll from "vue-infinite-scroll";

// import '../public/assets/css/style.css'

// import '../public/assets/css/_root.scss'

// import 'vue-toast-notification/dist/theme-sugar.css'

// import '../public/assets/css/style.css'
// import './assets/css/tailwind.css'

// import '@/utils/mixinSendAmplitude.js'

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

window.$ = window.jQuery = require("jquery");

Vue.use(infiniteScroll);
window.axios = require("axios");
Vue.prototype.$rf = new RequestFactory();

Vue.prototype.moment = moment;
window.moment = moment;

const role = Vue.observable({ role: ROLES_SYSTEM.ALL });

const user = Vue.observable({ user: null });

Object.defineProperty(Vue.prototype, "$user", {
  get() {
    return user.user;
  },

  set(value) {
    user.user = value;
  },
});

const targetRoute = Vue.observable({ targetRoute: '' })

Object.defineProperty(Vue.prototype, '$targetRoute', {
  get() {
    return targetRoute.targetRoute
  },

  set(value) {
    targetRoute.targetRoute = value
  }
})

const shoppingCart = Vue.observable({ shoppingCart: [] });

Object.defineProperty(Vue.prototype, "$shoppingCart", {
  get() {
    return shoppingCart.shoppingCart;
  },

  set(value) {
    shoppingCart.shoppingCart = value;
  },
});

Object.defineProperty(Vue.prototype, "$role", {
  get() {
    return role.role;
  },

  set(value) {
    role.role = value;
  },
});

const echo = Vue.observable({ echo: null })

Object.defineProperty(Vue.prototype, '$echo', {
  get() {
    return echo.echo
  },

  set(value) {
    echo.echo = value
  }
})
const conectedEcho = Vue.observable({ conectedEcho: null })

Object.defineProperty(Vue.prototype, '$conectedEcho', {
  get() {
    return conectedEcho.conectedEcho
  },

  set(value) {
    conectedEcho.conectedEcho = value
  }
})

Vue.directive("click-outside", {
  bind: function (el, binding, vnode) {
    el.event = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.event);
  },
  unbind: function (el) {
    document.body.removeEventListener("click", el.event);
  },
});

Vue.use(BootstrapVue, {
  breakpoints: [`xs`, "sm", "md", "lg", "xl", "xxl"],
});

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VBTooltipPlugin);
Vue.use(IconsPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
