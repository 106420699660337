import * as config from "@/utils/config"

import Vue from "vue"
import defaultImage from "@/assets/images/default.jpg"
import slugify from "slugify"

const appUtils = {
  addToCart(p, variant, count = 1) {
    const productIdx = Vue.prototype.$shoppingCart.findIndex(
      (item) => {
        return item.id === p.id && item.variant_id == variant
      }
    )
    if (productIdx > -1) {
      Vue.prototype.$shoppingCart[productIdx].count = count + Vue.prototype.$shoppingCart[productIdx].count
      Vue.prototype.$shoppingCart[productIdx].total_amount =
        Vue.prototype.$shoppingCart[productIdx].count *
        Vue.prototype.$shoppingCart[productIdx].selected_variant?.price
    } else {
      const newCount = count
      let product = {
        ...p,
        count: newCount,
        total_amount: newCount * p.selected_variant?.price,
        variant_id: variant
      }
      Vue.prototype.$shoppingCart.push(product)
    }

    // }
    localStorage.setItem(
      'HodoCart',
      JSON.stringify(Vue.prototype.$shoppingCart)
    )
  },
  subtractCart(p, count = 1) {
    const productIdx = Vue.prototype.$shoppingCart.findIndex(
      (item) => item.id === p.id
    )
    if (productIdx > -1) {
      Vue.prototype.$shoppingCart[productIdx].count -= count
      Vue.prototype.$shoppingCart[productIdx].total_amount =
        Vue.prototype.$shoppingCart[productIdx].count *
        Vue.prototype.$shoppingCart[productIdx].selected_variant?.price
    }
    localStorage.setItem(
      'HodoCart',
      JSON.stringify(Vue.prototype.$shoppingCart)
    )
  },
  rmfromCart(pid, variant) {
    const productIdx = Vue.prototype.$shoppingCart.findIndex(
      (item) => item.id === pid && item.variant_id === variant
    )
    Vue.prototype.$shoppingCart.splice(productIdx, 1)
    localStorage.setItem(
      'HodoCart',
      JSON.stringify(Vue.prototype.$shoppingCart)
    )
  },
  removeAllCartData() {
    Vue.prototype.$shoppingCart = []
    localStorage.removeItem('HodoCart')
  },
  setLocalToken(token) {
    localStorage.setItem(config.TOKEN_KEY, token)
  },
  setOrgId(org_id) {
    localStorage.setItem(config.ORG_ID, org_id)
  },
  getOrgId() {
    return Number(localStorage.getItem(config.ORG_ID))
  },
  removeOrgId() {
    localStorage.removeItem(config.ORG_ID)
  },
  setWsId(org_id) {
    localStorage.setItem(config.WS_ID, org_id)
  },
  getWsId() {
    return Number(localStorage.getItem(config.WS_ID))
  },
  removeWsId() {
    localStorage.removeItem(config.WS_ID)
  },
  setLocalTokenExpiration(expiration) {
    localStorage.setItem(config.TOKEN_EXPIRATION_KEY, expiration)
  },
  setLocalUser(user) {
    localStorage.setItem(config.USER, user)
  },
  getLocalToken() {
    return localStorage.getItem(config.TOKEN_KEY)
  },
  getLocalTokenExpiration() {
    return localStorage.getItem(config.TOKEN_EXPIRATION_KEY)
  },
  getLocalUser() {
    return JSON.parse(localStorage.getItem(config.USER))
  },
  removeLocalToken() {
    localStorage.removeItem(config.TOKEN_KEY)
  },
  removeLocalTokenExpiration() {
    localStorage.removeItem(config.TOKEN_EXPIRATION_KEY)
  },
  removeLocalUser() {
    localStorage.removeItem(config.USER)
  },
  removeAll() {
    sessionStorage.clear()
    this.removeLocalToken()
    this.removeLocalTokenExpiration()
    this.removeLocalUser()
    this.removeOrgId()
    this.removeWsId()
    this.removeAllCartData()
  },
  numberFormat(number, currencyUnit) {
    let data = number
      ?.toString()
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    // let data = number.toLocaleString(undefined, { minimumFractionDigits: 0 });
    if (currencyUnit) {
      data += ` ${currencyUnit}`
    }
    return data
  },
  removeVietnameseTones(str) {
    if (!str) return ""
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a")
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e")
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i")
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o")
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u")
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y")
    str = str.replace(/đ/g, "d")
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A")
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E")
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I")
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O")
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U")
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y")
    str = str.replace(/Đ/g, "D")
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "") // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, "") // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, " ")
    str = str.trim()
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    // str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ')
    return str
  },
  slugifyStr(str, replacement = "-") {
    if (!str || !str.length || typeof str === "undefined") return
    return slugify(str, {
      replacement, // replace spaces with replacement character, defaults to `-`
      remove: /[*+~.()'"!:@]/g, // remove characters that match regex, defaults to `undefined`
      lower: true, // convert to lower case, defaults to `false`
      strict: true, // strip special characters except replacement, defaults to `false`
      locale: "vi", // language code of the locale to use
    })
  },
  randomCode(length = 6) {
    return new Array(length).join().replace(/(.|$)/g, function () {
      return ((Math.random() * 36) | 0)
        .toString(36)
      [Math.random() < 0.5 ? "toString" : "toUpperCase"]()
    })
  },
  onHandleErrorLoadImage(e) {
    e.target.src = defaultImage
    e.onerror = null
  },
  truncateHash(string, startLength = 4, endLength = 4) {
    if (!string) return ""
    return `${string.substring(0, startLength)}...${string.substring(
      string.length - endLength
    )}`
  },
  formatDate(dateTime) {
    if (!dateTime) return Vue.prototype.moment().format("DD/MM/YYYY")
    return Vue.prototype.moment(dateTime).format("DD/MM/YYYY")
  },
  formatDateTime(dateTime) {
    if (!dateTime) return Vue.prototype.moment().format("HH:mm DD/MM/YYYY")
    return Vue.prototype.moment(dateTime).format("HH:mm DD/MM/YYYY")
  },
  uuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
      .replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
      .replace(/-/g, "")
  },
  getImageURL(path) {
    if (path.includes('https')) return path
    return process.env.VUE_APP_BASE_IMAGE_URL + 'storage/' + path
  },
  debounce(func, wait, immediate) {
    let timeout

    return function () {
      let context = this
      let args = arguments
      let callNow = immediate && !timeout

      clearTimeout(timeout)

      timeout = setTimeout(function () {
        timeout = null

        if (!immediate) {
          func.apply(context, args)
        }
      }, wait)
      if (callNow) func.apply(context, args)
    }
  },
}
export default appUtils
