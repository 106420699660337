import axios from '@axios'
import Vue from 'vue'
export default {
  namespaced: true,
  state: {
    cartItemsCount: (() => {
      const userData = JSON.parse(localStorage.getItem('userData'))
      return userData && userData.extras ? userData.extras.eCommerceCartItemsCount : 0
    })(),
    productDetail: {}
  },
  getters: {},
  mutations: {
    UPDATE_CART_ITEMS_COUNT(state, count) {
      state.cartItemsCount = count
    }
  },
  actions: {
    fetchProducts(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest('EcomRequest')
          .getListProducts(params)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchOrders(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest('EcomRequest')
          .getListOrders(params)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchProduct(ctx, slug) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest('EcomRequest')
          .getDetailProduct(slug)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrderDetail(ctx, id) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest('EcomRequest')
          .getDetailOrder(id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    cancelOrder(ctx, id) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest('EcomRequest')
          .cancelOrder(id)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchWishlistProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/ecommerce/wishlist')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCartProducts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/ecommerce/cart')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBrands(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest('EcomRequest')
          .getListBrands(params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOrigins(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest('EcomRequest')
          .getListOrigins(params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchManufacturers(ctx, params) {
      return new Promise((resolve, reject) => {
        Vue.prototype.$rf
          .getRequest('EcomRequest')
          .getListManufacturers(params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    // ------------------------------------------------
    // Product Actions
    // ------------------------------------------------
    addProductInWishlist(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/ecommerce/wishlist', { productId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductFromWishlist(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/ecommerce/wishlist/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },

    addProductInCart(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/ecommerce/cart', { productId })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeProductFromCart(ctx, { productId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/ecommerce/cart/${productId}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
